















































import { Vue, Component } from "vue-property-decorator";
import { RawLocation } from "vue-router";

@Component({
  metaInfo() {
    return {
      title: "Giriş Yap"
    };
  }
})
export default class LoginView extends Vue {
  form = {
    email: "",
    password: ""
  };

  alert = false;
  message = "";
  error = false;

  get emailRules(): unknown[] {
    return [
      (v: string) => !!v || "Lütfen e-postanızı yazınız",
      (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
    ];
  }

  get passwordRules(): unknown[] {
    return [(v: string) => !!v || "Lütfen parolanızı yazınız"];
  }

  get returnUrl(): RawLocation {
    let returnVal: RawLocation = { name: "Home" };

    if (this.$route.query.returnUrl) {
      returnVal = this.$route.query.returnUrl.toString();
    } else {
      const authData = this.$store.getters["auth/data"];

      if (authData.roles.indexOf("admin") >= 0) {
        returnVal = { name: "AdminHome" };
      } else {
        returnVal = { name: "Home" };
      }
    }

    return returnVal;
  }

  validate(): boolean {
    const form = this.$refs.form as HTMLFormElement;
    return form.validate();
  }

  reset(): void {
    const form = this.$refs.form as HTMLFormElement;
    form.reset();
  }

  resetValidation(): void {
    const form = this.$refs.form as HTMLFormElement;
    form.resetValidation();
  }

  async login(): Promise<void> {
    if (this.validate()) {
      try {
        await this.$store.dispatch("auth/login", {
          username: this.form.email,
          password: this.form.password
        });

        if (this.$store.getters["auth/isUserLoggedIn"]) {
          this.error = false;
          this.alert = true;
          this.message = "Bilgileriniz doğrulandı, lütfen bekleyiniz...";

          const timer1 = setInterval(() => {
            // Redirect returnUrl
            this.$router.push(this.returnUrl);

            clearInterval(timer1);
          }, 1000);
        } else {
          this.error = true;
          this.alert = true;
          this.message = "Bilgileriniz doğrulanmadı. Kontrol ettikten sonra tekrar deneyiniz.";
        }
      } catch (e) {
        this.message = (e as Error).message;
        this.error = true;
        this.alert = true;
      }
    }
  }

  openRegister(): void {
    var returnUrl = this.$route.query.returnUrl;
    
    if (returnUrl) {
      this.$router.push({ name: 'Register', query: { returnUrl: returnUrl.toString() }});
    } else {
      this.$router.push({ name: 'Register'});
    }
  }

  mounted(): void {
    this.$emit("setTitle", "Giriş");
  }
}
